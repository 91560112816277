/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ticket-types-select {
  min-width: 150px;
}
.button-remark {
  color: #8c8c8c;
  font-size: 0.8em;
  margin-top: -8px;
  margin-bottom: 16px;
}
.active-ticket-chart {
  width: 25%;
}
.active-ticket-chart-panel {
  display: flex;
  flex-direction: 'row';
  justify-content: 'space-between';
  margin-left: 'auto';
  margin-right: 'auto';
}
.ticket-status-select {
  min-width: 250px;
  max-width: 550px;
}
